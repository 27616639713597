import { gql } from '@apollo/client';
import VehicleListingPage from '../../components/VehicleListingPage';
import { getApolloClientServerSide } from '../../clients/hasura';
import { getShowroomServerSide, Showroom } from '../../services/showrooms/getShowroomServerSide';

type Props = {
  showroom: Showroom;
};

export default function DefaultVehicleList(props: Props) {
  return (
    <VehicleListingPage
      title="New & used electric cars for sale. "
      description="100% online. 7 day money-back guarantee. Delivered to your door."
      metaTitle="Browse electric cars for sale"
      metaDescription="Check out our range of zero-emission vehicles that are good for the planet and your back pocket."
      financeSettings={props.showroom.public_settings}
      whereClause={{
        is_listed: { _eq: true },
      }}
    />
  );
}

export const getServerSideProps = async (context) => {
  const showroom = await getShowroomServerSide(context.req, context.res);
  const apolloClient = getApolloClientServerSide(context.req, context.res);

  const response = await apolloClient.query({
    query: gql`
      query DefaultVehicleList($showroomId: uuid!) {
        vehicle_lists(where: { showroom_id: { _eq: $showroomId }, is_default: { _eq: true } }) {
          code
        }
      }
    `,
    variables: { showroomId: showroom.id },
  });

  const defaultVehicleList = response.data?.vehicle_lists[0];

  if (defaultVehicleList) {
    const search = new URLSearchParams(context.query).toString();
    const destination = `/vehicles/list/${defaultVehicleList.code}?${search}`;

    return {
      redirect: {
        destination,
        permanent: true,
      },
      props: {},
    };
  }

  return {
    props: {
      showroom,
    },
  };
};
